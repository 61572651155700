<template>
    <activix-modal
        size="lg"
        :opened="opened"
        @open="onOpen"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ group ? $t('groups.editGroup') : $t('groups.newGroup') }}
            </h4>
        </template>

        <template slot="body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group | text-left">
                        <label for="name">{{ $t('groups.name') }}</label>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('groups.name')"
                            v-model="name"
                        />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group | text-left">
                        <label for="type">{{ $t('groups.type') }}</label>
                        <activix-multiselect
                            class="w-full"
                            popper-class="absolute top-0 left-0 mt-10"
                            :selected="type"
                            :multiple="false"
                            :clearable="false"
                            :searchable="false"
                            :allow-empty="false"
                            :options="typeOptions"
                            identifier="value"
                            label="label"
                            @update="updateType"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group | text-left">
                        <label for="accounts">{{ $t('groups.accounts') }}</label>
                        <activix-multiselect
                            class="w-full"
                            popper-class="absolute top-0 left-0 mt-10"
                            identifier="id"
                            label="name"
                            multiple="multiple"
                            multiple-limit="3"
                            :clearable="true"
                            :searchable="true"
                            :allow-empty="false"
                            :options="accountList"
                            :close-on-select="false"
                            :taggable="true"
                            :selected="associatedAccounts"
                            @update="updateAssociatedAccounts"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button
                type="primary"
                :disabled="!isSubmitable"
                :loading="$wait.is('group.saving')"
                @click="saveGroup"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            group: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                typeOptions: [
                    { value: 'maker', label: this.$t('groups.types.maker') },
                    { value: 'franchise', label: this.$t('groups.types.franchise') },
                ],
                name: '',
                type: 'maker',
                associatedAccounts: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['accounts']),

            accountList() {
                return this.accounts;
            },

            isSubmitable() {
                return this.name && this.type && this.associatedAccounts.length > 0;
            },
        },
        methods: {
            updateType(value) {
                this.type = value;
            },

            updateAssociatedAccounts(value) {
                this.associatedAccounts = value;
            },

            async saveGroup() {
                const mode = this.group ? 'update' : 'store';

                this.$wait.start('group.saving');

                try {
                    if (mode === 'store') {
                        await this.$api.groups.store({
                            name: this.name,
                            type: this.type.value,
                            accounts: this.associatedAccounts,
                        });
                    } else {
                        await this.$api.groups.update(this.group.id, {
                            name: this.name,
                            type: this.type.value,
                            accounts: this.associatedAccounts,
                        });
                    }

                    this.$wait.end('group.saving');

                    this.$notify.success(this.$t(`groups.alerts.${mode}.success`));

                    this.$emit('refresh');
                    this.close();
                } catch (error) {
                    this.$wait.end('group.saving');
                    this.$notify.error(this.$t(`groups.alerts.${mode}.error`));

                    throw error;
                }
            },

            close() {
                this.$emit('update:opened', false);
            },

            onOpen() {
                if (this.group) {
                    this.name = this.group.name;
                    this.type = {
                        value: this.group.type,
                        label: this.$t(`groups.types.${this.group.type}`),
                    };
                    this.associatedAccounts = this.group.accounts;
                }
            },

            onClosed() {
                this.name = '';
                this.type = { value: 'maker', label: this.$t('groups.types.maker') };
                this.associatedAccounts = [];
                this.$emit('closed');
            },
        },
    };
</script>
