<template>
    <div>
        <portal to="content-header-left">
            <activix-reload :loading="$wait.is('fetching.groups')" @click="fetchGroups" />
        </portal>

        <div class="box" :class="{ loading: $wait.is('fetching.groups') }">
            <div class="box-header | h-auto p-6 flex justify-between">
                <a class="btn btn-primary" @click="onAdd">
                    <icon name="bold/add" class="mr-2 text-sm" />
                    {{ $t('general.add') }}
                </a>
                <input
                    class="form-control w-auto"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
            <div class="box-body | p-0">
                <el-table
                    class="w-full border-t-2 border-b"
                    :border="true"
                    :data="paginatedGroups"
                    :default-sort="{ prop: 'name', order: 'ascending' }"
                    :stripe="true"
                    @sort-change="updateTableSorting"
                >
                    <el-table-column
                        :label="$t('users.edit.name')"
                        :sortable="true"
                        prop="name"
                        header-align="center"
                        min-width="160"
                        width="160"
                    />
                    <el-table-column
                        :label="$t('modal.type')"
                        :sortable="true"
                        prop="type"
                        header-align="center"
                        width="115"
                    >
                        <template slot-scope="scope">
                            {{ $t(`groups.types.${scope.row.type}`) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('groups.relatedAccounts')"
                        :sortable="true"
                        prop="accountsList"
                        header-align="center"
                    />
                    <el-table-column
                        :label="$t('groups.relatedUsers')"
                        :sortable="true"
                        prop="usersList"
                        header-align="center"
                    />
                    <el-table-column
                        label=""
                        header-align="center"
                        width="90"
                        min-width="90"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <a class="link-grey" @click="onEdit(scope.row)">
                                <activix-tooltip :content="$t('clientCard.edit')">
                                    <icon name="regular/pencil-1" />
                                </activix-tooltip>
                            </a>
                            <a class="link-grey ml-3 | hover:text-red-500" @click="onDelete(scope.row.id)">
                                <activix-tooltip :content="$t('general.delete')">
                                    <icon :name="$icons.trash" />
                                </activix-tooltip>
                            </a>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="p-6"
                    :layout="paginationLayout"
                    :background="true"
                    :current-page.sync="tableState.currentPage"
                    :page-size.sync="tableState.perPage"
                    :page-sizes="tableState.pageOptions"
                    :pager-count="5"
                    :total="filteredSortedGroups.length"
                    @size-change="updateTablePerPage"
                />
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :content="$t('groups.deleteGroup')"
            :opened.sync="deleteModal.opened"
            @approve="deleteGroup"
            @closed="onModalClose"
        />

        <group-modal
            :opened.sync="addEditModal.opened"
            :group="addEditModal.group"
            @refresh="fetchGroups"
            @closed="onModalClose"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { orderBy } from 'lodash-es';
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import GroupModal from '../../components/modals/Group.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            GroupModal,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                groups: [],
                deleteModal: {
                    opened: false,
                    groupId: null,
                },
                addEditModal: {
                    opened: false,
                    group: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),

            filteredSortedGroups() {
                const searchedData = this.searchData(this.tableState.searchToken, this.groups);
                const sortedData = orderBy(
                    searchedData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedGroups() {
                return this.filteredSortedGroups.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        methods: {
            buildAccountList(accounts) {
                let list = '';

                if (accounts != null) {
                    accounts.forEach((account, index) => {
                        if (index != 0) {
                            list += ', ';
                        }

                        list += account.name;
                    });
                }

                return list;
            },

            buildUserList(users) {
                let list = '';

                if (users != null) {
                    users.forEach((user, index) => {
                        if (index != 0) {
                            list += ', ';
                        }

                        list += `${user.first_name} ${user.last_name}`;
                    });
                } else {
                    list += 'N/A';
                }

                return list;
            },

            async fetchGroups() {
                this.$wait.start('fetching.groups');

                try {
                    const response = await this.$api.groups.index({
                        include: ['accounts:id,name', 'users:id,first_name,last_name'],
                    });

                    const groups = response.map(group => {
                        group.accountsList = this.buildAccountList(group.accounts);
                        group.usersList = this.buildUserList(group.users);

                        return group;
                    });

                    this.groups = groups;

                    this.$wait.end('fetching.groups');
                } catch (error) {
                    this.$wait.end('fetching.groups');
                    this.$notify.error(this.$t('groups.alerts.index.error'));

                    throw error;
                }
            },

            async deleteGroup() {
                const id = this.deleteModal.groupId;

                if (!id) {
                    return;
                }

                try {
                    await this.$api.groups.destroy(id);

                    this.$notify.success(this.$t('groups.alerts.destroy.success'));
                    this.fetchGroups();
                } catch (error) {
                    this.$notify.error(this.$t('groups.alerts.destroy.error'));

                    throw error;
                }
            },

            onEdit(group) {
                this.addEditModal.opened = true;
                this.addEditModal.group = group;
            },

            onDelete(id) {
                this.deleteModal.opened = true;
                this.deleteModal.groupId = id;
            },

            onAdd() {
                this.addEditModal.opened = true;
            },

            onModalClose() {
                this.deleteModal.groupId = null;
                this.addEditModal.group = null;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.fetchGroups();
            });
        },
    };
</script>
